import classNames from 'classnames';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as AttachmentDoc } from '../../assets/icons/AttachmentDoc.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatDate, formatFileName, formatText, getFormattedNumber } from '../../helpers/utils';
import { useWindowSize } from '../../hooks/useWindowSize';
import { downloadFile } from '../../store/features/downloadSlice';
import { getQuoteAttachmentDetails } from '../../store/features/quoteSlice';
import { addToast } from '../../store/features/toastSlice';
import NoData from '../common/no-data';
import TableHeader from '../common/table-header';
import Header from '../elements/header';
import QuoteProductListItem from './quote-product-list';

const QuotesPrivew = ({ quoteDetails, isQuoteDataLoading, quoteDetailsError }) => {
  const { t } = useTranslation();

  const QuoteTableHeaders = useMemo(() => {
    return [
      { name: t('DESCRIPTION'), key: 'DESCRIPTION' },
      { name: t('QTY'), key: 'QTY' },
      { name: t('UNIT_PRICE'), key: 'UNIT_PRICE' },
      { name: t('DISCOUNT'), key: 'DISCOUNT' },
      { name: t('TAX'), key: 'TAX' },
      { name: t('TOTAL'), key: 'AMOUNT' },
    ];
  }, []);

  const { token, setModal } = useContext(OrganisationContext);

  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const [quotesAttachmentsDetails, setQuotesAttachmentDetails] = useState([]);

  const totalDiscountAmount = quoteDetails?.products?.reduce((sum, product) => sum + product.discount_amount, 0);
  const dateExpiry = formatDate(quoteDetails?.expiry_date);
  const dateIssued = formatDate(quoteDetails?.issued_date);
  const showActionButtons =
    quoteDetails?.requires_acceptance && quoteDetails?.status !== 'REJECTED' && quoteDetails?.status !== 'ACCEPTED';

  const getFormattedAddress = addresses => {
    const primaryAddress = addresses.find(address => address.is_primary);
    if (primaryAddress) {
      return primaryAddress.formatted_address;
    }
    if (addresses.length > 0) {
      return addresses[0].formatted_address;
    }
    return 'No Address';
  };

  const onReject = () => {
    setModal({
      type: 'quotes-reject',
      content: {},
      title: t('REJECT_QUOTES_TITLE'),
    });
  };

  const onAccept = () => {
    setModal({
      type: 'quotes-accepted',
      content: {},
      title: t('ACCEPT_QUOTES_TITLE'),
    });
  };

  const handleAttachmentDownload = attachment_id => {
    const quotesAttachmentsDetail = quotesAttachmentsDetails.find(qa => qa.id === attachment_id);
    if (quotesAttachmentsDetail) {
      const { media, name } = quotesAttachmentsDetail || {};
      if (media?.url) {
        dispatch(
          downloadFile({
            url: media.url,
            filename: name,
          }),
        );
      }
    } else {
      dispatch(getQuoteAttachmentDetails({ quote_id: quoteDetails.id, token, attachment_id })).then(data => {
        const { media, name } = data || {};
        if (media?.url) {
          dispatch(
            downloadFile({
              url: media.url,
              filename: name,
              onSuccess: () => setQuotesAttachmentDetails(prev => [...prev, data]),
            }),
          );
        } else {
          dispatch(addToast({ error: true, text: t('THERE_IS_NO_MEDIA_ATTECHED'), id: nanoid() }));
        }
      });
    }
  };

  const QuoteDetails = ({ label, value }) => (
    <div className="flex items-center border-bottom bg-lightgray-1">
      <div className={classNames('px-1_5  border-right w-30 py-1', width >= 576 && 'w-20 py-2')}>
        <p className="inter-400-text natural-500-text font-14 line-height-20 respo-font-text">{label}</p>
      </div>
      <div className="w-full px-1_5 py-2 bg-white">
        <p
          className={classNames(
            'inter-500-text natural-900-text font-14 line-height-9 respo-font-text',
            width >= 576 && 'line-height-20',
          )}>
          {value}
        </p>
      </div>
    </div>
  );

  const JobDetails = ({ quoteCode, jobNumber, jobAddress, issuedDate, expiryDate, description }) => (
    <div className="overflow-hidden border-1 radius-1_5 mb-4 jobs-details-box">
      <QuoteDetails label={t('QUOTE_NUMBER')} value={quoteCode} />
      <QuoteDetails label={t('JOB_NUMBER')} value={jobNumber} />
      <QuoteDetails label={t('JOB_ADDRESS')} value={jobAddress} />
      <QuoteDetails label={t('ISSUED_DATE')} value={issuedDate} />
      <QuoteDetails label={t('EXPIRY_DATE')} value={expiryDate} />
      <QuoteDetails label={t('DESCRIPTION_QUOTE')} value={description} />
    </div>
  );

  const ProductDetails = ({ QuoteTableHeaders, isQuoteDataLoading, quoteDetails }) => (
    <div className="w-full bg-natural-500 radius-1_5 border overflow-scroll mb-4">
      <div className="flex-column w-fit-content min-w-full h-full ">
        <TableHeader
          headers={QuoteTableHeaders}
          headerClassName={!(quoteDetails?.products?.length > 0) && 'header-nodata'}
          headerLabelClassName="line-hight-20 white-text inter-400-text border-right-white pxy-2 font-14 respo-font-text product-header-label"
        />
        {isQuoteDataLoading ? (
          <div className="item flex items-center justify-center flex-1 mb-6 bg-white pxy-3">
            <Skeleton height={'100%'} containerClassName="line-height-1 h-full w-full" />
          </div>
        ) : quoteDetails?.products?.length > 0 ? (
          quoteDetails.products.map((listItem, index) => <QuoteProductListItem key={index} listItem={listItem} />)
        ) : (
          <div className="flex-column flex-1 items-center justify-center bg-white pxy-3">
            <NoData title={t('NO_PRODUCT')} />
          </div>
        )}
      </div>
    </div>
  );

  const PaymentDetail = ({ label, value }) => (
    <div className="flex items-center col-gap-1">
      <p className="inter-400-text natural-500-text font-14 line-height-20 respo-font-text">{label}:</p>
      <span className="inter-500-text natural-900-text font-14 line-height-20 respo-font-text">{value}</span>
    </div>
  );

  const AttachmentsDetails = ({ attachments = [] }) => {
    const attachmentsLength = attachments.length;
    if (attachmentsLength > 0) {
      return (
        <div>
          <p className="font-14 inter-600-text line-height-20 mb-2 natural-900-text">
            {`${t('ATTACHMENTS')} (${attachmentsLength})`}
          </p>
          {attachmentsLength > 0 && (
            <div className="inline-flex-column gap-6px">
              {attachments.map(({ id, name }, i) => {
                const [attachmentName, attachmentExt] = name.split('.');
                return (
                  <div key={id} className="flex gap-1 cursor" onClick={() => handleAttachmentDownload(id)}>
                    <span>
                      <AttachmentDoc width={14} height={14} className="w-14px h-14p primary-500-text " />
                    </span>
                    <p className="primary-500-text inter-500-text font-14 line-height-20">
                      {`${formatFileName(attachmentName)}.${attachmentExt}`}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    }
    return <></>;
  };

  const Divider = () => <div className="bg-natural-200 w-full my-4 divider"></div>;

  const PaymentTermsSection = ({ paymentTerms }) => (
    <>
      {paymentTerms?.length > 0 ? (
        <div>
          <p className="inter-600-text natural-900-text font-14 line-height-20 pb-2 respo-font-text total-detail">
            {t('PAYMENT_TERMS')}
          </p>
          {paymentTerms.map((itemPayment, index) => (
            <div key={index} className="flex items-center col-gap-2">
              <PaymentDetail
                label={itemPayment?.name}
                value={itemPayment?.percentage ? `${itemPayment?.percentage}%` : '-'}
              />
              <PaymentDetail label={t('MILESTONE')} value={capitalize(formatText(itemPayment?.description)) || '-'} />
              <PaymentDetail
                label={t('DUE_AFTER')}
                value={itemPayment?.due_count ? `${itemPayment?.due_count} days` : '-'}
              />
            </div>
          ))}
        </div>
      ) : (
        <NoData title={t('NO_PAYMENT_TERMS')} className="items-start" />
      )}
    </>
  );

  const TermsDetailSection = ({ quoteDetails }) => (
    <>
      {quoteDetails?.term && (
        <div className="py-4">
          <p className="inter-600-text natural-900-text font-14 line-height-20 pb-2 respo-font-text total-detail">
            {t('TERMS')}
          </p>
          <span className="inter-500-text natural-900-text font-12 line-height-9 respo-font-text">
            {quoteDetails?.term}
          </span>
        </div>
      )}
    </>
  );

  const TotalDetailItem = ({ label, value }) => (
    <div className="pxy-2 w-full border-right-white">
      <p className="inter-400-text natural-50-text font-12 line-height-20 respo-font-small">{label}</p>
      <p className="inter-500-text natural-50-text font-14 line-height-20 respo-font-text total-detail">{value}</p>
    </div>
  );

  const TotalDetails = ({ quoteDetails }) => (
    <div className="mb-4 bg-natural-500 flex items-center justify-between radius-1_5">
      <TotalDetailItem
        label={t('SUB_TOTAL')}
        value={quoteDetails?.total_net ? getFormattedNumber(quoteDetails?.total_net) : '-'}
      />
      <TotalDetailItem
        label={t('DISCOUNT')}
        value={totalDiscountAmount ? getFormattedNumber(totalDiscountAmount) : '-'}
      />
      <TotalDetailItem
        label={t('TAX')}
        value={quoteDetails?.total_tax ? getFormattedNumber(quoteDetails?.total_tax) : '-'}
      />
      <TotalDetailItem
        label={t('TOTAL')}
        value={quoteDetails?.total_amount ? getFormattedNumber(quoteDetails?.total_amount) : '-'}
      />
    </div>
  );

  return quoteDetails?.status === 'DRAFT' || quoteDetailsError ? (
    <QuotesInfoWrapper>
      <div className="bg-white radius-4 py-8 px-16 quotes-info-box flex-column row-gap-4">
        <p className="inter-700-text text-black font-28 line-height-36 text-center info-title">
          {t('QUOTES_INFO_TITLE')}
        </p>
        <p className="inter-400-text natural-500-text font-18 line-height-20 text-center info-description">
          {quoteDetailsError ? quoteDetailsError : t('QUOTES_INFO_DESCRIPTION')}
        </p>
      </div>
    </QuotesInfoWrapper>
  ) : (
    <PreviewQuotesWrapeer className="flex col-gap-6 overflow-hidden w-60 h-full bg-white">
      <Header
        onReject={onReject}
        onAccept={onAccept}
        quoteDetails={quoteDetails}
        dateExpiry={dateExpiry}
        showActionButtons={showActionButtons}
      />
      <div className="pxy-12 w-full flex card-sec-detail overflow-scroll">
        <div className="card-sec-detail-wrapper w-full">
          <div
            className={classNames(
              'mb-4 flex justify-between items-center',
              quoteDetails?.quote_template?.image?.alignment === 'LEFT' && 'flex-row-rev',
            )}>
            <div
              className={classNames(
                'flex',
                quoteDetails?.quote_template?.image?.alignment === 'CENTRE' && 'w-80 title-quote-res',
              )}>
              <p className="font-28 inter-600-text line-height-24 respo-title">Quote</p>
            </div>
            <div
              className={classNames('flex', quoteDetails?.quote_template?.image?.alignment === 'CENTRE' && 'w-full')}>
              <img
                src={quoteDetails?.quote_template?.image?.media?.url}
                alt=""
                width={quoteDetails?.quote_template?.image?.media?.width || 97}
                height={quoteDetails?.quote_template?.image?.media?.height || 47}
                className="quote-template-logo"
              />
            </div>
          </div>

          <div className="flex mb-4">
            {/* <div className="flex-1">
              <p className="inter-600-text text-black font-12 line-height-20 pb-1 company-name-title ">
                {uppercase(formatText(quoteDetails?.salesperson?.organization?.name || '-'))}
              </p>
              <div className="flex items-center col-gap-1">
                <p className="inter-400-text text-black font-14 line-height-20 code-quotes">{t('QUOTE')}:</p>
                <span className="inter-600-text text-black font-14 line-height-20 code-quotes ">
                  {quoteDetails?.code || '-'}
                </span>
              </div>
            </div> */}
            {/* <div className="flex-1">
              <label className="inter-400-text natural-500-text font-14 line-height-20 respo-font-text pb-1">
                {t('TO')}:
              </label>
              <p className="white-space-pre m-0 inter-500-text natural-900-text font-14 form-info respo-font-text line-height-20">
                {quoteDetails?.quote_template?.contact_information}
              </p>
            </div> */}
            <div className="flex-1">
              <label className="inter-400-text natural-500-text font-14 line-height-20 respo-font-text pb-1">
                {t('FROM')}:
              </label>
              <p className="white-space-pre m-0 inter-500-text natural-900-text font-14 form-info respo-font-text line-height-20">
                {quoteDetails?.quote_template?.contact_information}
              </p>
            </div>
          </div>
          <JobDetails
            quoteCode={quoteDetails?.code}
            jobNumber={quoteDetails?.job?.job_number || '-'}
            jobAddress={quoteDetails?.engagement?.property?.formatted_address || '-'}
            issuedDate={dateIssued || '-'}
            expiryDate={dateExpiry || '-'}
            description={quoteDetails?.description || '-'}
          />
          <ProductDetails
            QuoteTableHeaders={QuoteTableHeaders}
            isQuoteDataLoading={isQuoteDataLoading}
            quoteDetails={quoteDetails}
          />
          <TotalDetails quoteDetails={quoteDetails} />
          <AttachmentsDetails attachments={quoteDetails.attachments} />
          <Divider />
          <PaymentTermsSection paymentTerms={quoteDetails?.payment_terms_template?.payment_terms} />
          <TermsDetailSection quoteDetails={quoteDetails} />
        </div>
      </div>
    </PreviewQuotesWrapeer>
  );
};

const PreviewQuotesWrapeer = styled.div`
  top: 5.5rem;
  position: relative;

  .quote-template-logo {
    aspect-ratio: 16 / 9;
    object-fit: contain;
    width: auto;
  }

  .divider {
    height: 1px;
  }

  .product-header-label {
    border-right: none;
  }

  .jobs-details-box div:last-child {
    border-bottom: none;
  }

  .card-sec-detail {
    height: calc(100% - 92px);
  }
  .card-sec-detail-wrapper > div:last-child:not(.divider) {
    padding-bottom: 40px;
  }
  @media (max-width: 576px) {
    top: 0 !important;
  }

  @media (max-width: 1280px) {
    width: 70% !important;
  }
  @media (max-width: 1024px) {
    width: 80% !important;
  }
  @media (max-width: 768px) {
    width: 100% !important;
  }
  @media (max-width: 576px) {
    .respo-font-text,
    .respo-font-small {
      font-size: 10px !important;
    }
    .respo-title {
      font-size: 16px;
    }
    .title-quote-res {
      width: 70%;
    }
    .company-name-title {
      font-size: 12px;
    }
    .code-quotes {
      font-size: 14px;
    }
    .form-info {
      line-height: 16px !important;
    }
  }
  @media (max-width: 479px) {
    .respo-font-text,
    .respo-font-small {
      font-size: 8px !important;
    }
    .title-quote-res {
      width: 60%;
    }
    .company-name-title {
      font-size: 10px;
    }
    .form-info {
      line-height: 12px !important;
    }

    .total-detail {
      font-size: 9px !important;
      padding-bottom: 0 !important;
    }
  }
  .card-sec-detail {
    @media (max-width: 768px) {
      padding: 32px !important;
    }
    @media (max-width: 425px) {
      padding: 24px !important;
    }
    @media (max-width: 375px) {
      padding: 20px !important;
    }
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) 80px 120px 90px 80px 100px;
    width: 100%;
    padding: 0;
    @media (max-width: 1440px) {
      grid-template-columns: minmax(130px, 1fr) 80px 100px 90px 80px 100px;
    }
    @media (max-width: 576px) {
      grid-template-columns: minmax(120px, 1fr) 55px 75px 75px 55px 80px;
    }
    @media (max-width: 479px) {
      grid-template-columns: minmax(80px, 1fr) 50px 50px 60px 45px 50px;
    }
  }

  .header-nodata {
    @media (max-width: 400px) {
      grid-template-columns: minmax(80px, 1fr) 46px 64px 60px 40px 50px !important;
    }
    @media (max-width: 375px) {
      grid-template-columns: minmax(72px, 1fr) 46px 64px 60px 40px 50px !important;
    }
  }
`;

const QuotesInfoWrapper = styled.div`
  width: 490px;
  @media (max-width: 520px) {
    padding: 20px !important;
    width: 100% !important;
  }

  .quotes-info-box {
    box-shadow: 0px 4px 6px -4px #1018281a;
    @media (max-width: 520px) {
      padding: 32px;
    }
    @media (max-width: 425px) {
      padding: 24px;
      .info-description {
        font-size: 16px;
      }
      .info-title {
        font-size: 26px;
      }
    }
  }
`;

export default QuotesPrivew;
